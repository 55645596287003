define('illustrify/services/current-agency', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = Ember.Service.extend({
    session: service(),
    store: service(),
    agencyId: null,
    name: null,
    address: null,
    phoneNumber: null,
    canInvite: true,
    canAddClient: true,
    canAddIllustration: true,
    load: function load() {
      var _this = this;

      if (this.get('session.isAuthenticated')) {
        return this.get('store').queryRecord('user-agency', {}).then(function (agency) {
          _this.set('agencyId', agency.id);
          agency.eachAttribute(function (name) {
            _this.set(name, agency.get(name));
          });
        });
      }
    }
  });
});