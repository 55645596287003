define('illustrify/components/illustration-view-illustration', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service;
  exports.default = Ember.Component.extend({
    currentUser: service(),
    dataRows: [],
    minNetDB: 0,
    maxNetDB: 0,
    minTotalOutlay: 0,
    maxTotalOutlay: 0,
    minIntRate: 0,
    maxIntRate: 0,
    debugFlag: false,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.sortOrder = ['age:asc'];
      this.dataRows = computed.sort('illustration.proposalData', 'sortOrder');
      this.totalOutlays = computed.mapBy('illustration.proposalData', 'totalOutlay');
      this.minTotalOutlay = computed.min('totalOutlays');
      this.maxTotalOutlay = computed.max('totalOutlays');
      this.interestRates = computed.mapBy('illustration.proposalData', 'interestRate');
      this.minIntRate = computed.min('interestRates');
      this.maxIntRate = computed.max('interestRates');
      this.netDbAfterLoanRepays = computed.mapBy('illustration.proposalData', 'netDbAfterLoanRepay');
      this.minNetDB = computed.min('netDbAfterLoanRepays');
      this.maxNetDB = computed.max('netDbAfterLoanRepays');
    },

    actions: {
      toggleDebug: function toggleDebug() {
        this.toggleProperty('debugFlag');
      }
    }
  });
});