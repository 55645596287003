define('illustrify/models/policy', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    default: _emberData.default.attr('boolean'),
    scpDefault: _emberData.default.attr('boolean'),
    isTraditional: _emberData.default.attr('boolean'),
    isScp: _emberData.default.attr('boolean'),
    isActive: _emberData.default.attr('boolean'),
    isPasted: _emberData.default.attr('boolean')
  });
});