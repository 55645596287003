define('illustrify/models/proposal-datum', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    age: _emberData.default.attr('number'),
    year: _emberData.default.attr('number'),
    premium: _emberData.default.attr('number'),
    accruedLoan: _emberData.default.attr('number'),
    advancedDrawFeww: _emberData.default.attr('number'),
    advancedRateFee: _emberData.default.attr('number'),
    arrangementFee: _emberData.default.attr('number'),
    interestRate: _emberData.default.attr('number'),
    loanInterestOverage: _emberData.default.attr('number'),
    loanInterestOwed: _emberData.default.attr('number'),
    loanInterestPaid: _emberData.default.attr('number'),
    loanInterestPaidActual: _emberData.default.attr('number'),
    ddaAccountValue: _emberData.default.attr('number'),
    loanPayoffFromPolicy: _emberData.default.attr('number'),
    totalOutlay: _emberData.default.attr('number'),
    cashSurrenderValue: _emberData.default.attr('number'),
    cashSurrenderValueAtValue: _emberData.default.attr('number'),
    collateralRequired: _emberData.default.attr('number'),
    collateralRequiredStress: _emberData.default.attr('number'),
    collateralStressRate: _emberData.default.attr('number'),
    deathBenefit: _emberData.default.attr('number'),
    netDbAfterLoanRepay: _emberData.default.attr('number'),
    runningBalanceOverpaid: _emberData.default.attr('number')
  });
});