define('illustrify/validators/validate-custom-number', ['exports', 'ember-changeset-validations/utils/validation-errors'], function (exports, _validationErrors) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatDescription = formatDescription;
  exports.default = validateCustomNumber;
  var dasherize = Ember.String.dasherize,
      isEmpty = Ember.isEmpty;
  var keys = Object.keys;
  function formatDescription() {
    var key = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

    var split_key = key.split('.');
    var newKey = split_key[split_key.length - 1];
    return dasherize(newKey).split(/[_-]/g).join(' ');
  }

  function _validateType(type, opts, numValue, key, changes, content) {
    var cmp_field_key = opts[type];
    if (!_.isString(cmp_field_key)) {
      return 'Comparison must be a string field key';
    }

    var expected = parseInt(content.get(cmp_field_key));

    if (changes.hasOwnProperty(cmp_field_key)) {
      expected = parseInt(changes[cmp_field_key]);
    }

    if (_.isUndefined(expected) || !_.isNumber(expected)) {
      return 'Comparison field is invalid';
    }

    var context = {};
    context[type] = expected + ' (' + formatDescription(cmp_field_key) + ')';

    if (type === 'is' && numValue !== expected) {
      return (0, _validationErrors.default)(key, 'equalTo', numValue, context);
    } else if (type === 'lt' && numValue >= expected) {
      return (0, _validationErrors.default)(key, 'lessThan', numValue, context);
    } else if (type === 'lte' && numValue > expected) {
      return (0, _validationErrors.default)(key, 'lessThanOrEqualTo', numValue, context);
    } else if (type === 'gt' && numValue <= expected) {
      return (0, _validationErrors.default)(key, 'greaterThan', numValue, context);
    } else if (type === 'gte' && numValue < expected) {
      return (0, _validationErrors.default)(key, 'greaterThanOrEqualTo', numValue, context);
    }

    return true;
  }

  function validateCustomNumber() {
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    return function (key, newValue, oldValue, changes, content) {
      var numValue = Number(newValue);
      var optKeys = keys(options);

      if (typeof newValue === 'string' && isEmpty(newValue)) {
        return (0, _validationErrors.default)(key, 'notANumber', numValue, options);
      }

      if (!_.isNumber(numValue)) {
        return (0, _validationErrors.default)(key, 'notANumber', numValue, options);
      }

      for (var i = 0; i < optKeys.length; i++) {
        var type = optKeys[i];
        var m = _validateType(type, options, numValue, key, changes, content);

        if (typeof m === 'string') {
          return m;
        }
      }

      return true;
    };
  }
});