define('illustrify/router', ['exports', 'illustrify/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('secure', function () {
      this.route('illustration', function () {
        this.route('view', { path: ':illustration_id' });
        this.route('loading');
      });
      this.route('illustrations');
      this.route('client', function () {
        this.route('view', { path: ':client_id' });
        this.route('loading');
      });
      this.route('clients');
      this.route('account');
      this.route('agents', function () {
        this.route('view', { path: ':agent_id' });
        this.route('new');
      });
      this.route('agency');
      this.route('loading');
      this.route('security');
      this.route('agencies', function () {
        this.route('new');
        this.route('view');
      });
    });
    this.route('register');
    this.route('forgotpw');
    this.route('resetpw', { path: 'reset/:token' });
    this.route('loading');
  });

  exports.default = Router;
});