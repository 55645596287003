define("illustrify/components/illustration-view", ["exports", "ember-changeset", "illustrify/validations/illustration"], function (exports, _emberChangeset, _illustration) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service;
  exports.default = Ember.Component.extend({
    setupData: service(),
    currentUser: service(),
    sentryLogger: service(),
    flashMessages: service(),
    isSmallCasePlatform: computed.equal("changeset.programType", "insured_ret"),
    hasProposalData: computed("model.proposalData", function () {
      return this.get("model.proposalData").length > 0;
    }),
    isValidYrs: computed("changeset.yearsToPayInterest", function () {
      var lower_value = this.get("model.isSmallCasePlatform") ? "5" : "0";
      var is_scp = this.get("model.isSmallCasePlatform") ? "SCP: " : "Traditional: ";
      var vy = "undefined" !== typeof this.get("changeset.yearsToPayInterest") ? this.get("changeset.yearsToPayInterest") : this.get("model.yearsToPayInterest");
      vy = parseInt(vy);
      if (!Number.isInteger(vy) && !(parseInt(vy) === vy) || 40 < vy || lower_value > vy) {
        this.get("flashMessages").danger(is_scp + "Please enter a value between " + lower_value + " and 40...");
        return false;
      }
      return true;
    }),
    isValidAddPayment: computed("changeset.additionalInterestPayment", function () {
      var aip = "undefined" !== typeof this.get("changeset.additionalInterestPayment") ? this.get("changeset.additionalInterestPayment") : this.get("model.additionalInterestPayment");
      if (!Number.isInteger(aip) && !(parseInt(aip) === aip) || 0 > aip) {
        this.get("flashMessages").danger("Please enter a positive value...");
        return false;
      }
      return true;
    }),
    init: function init() {
      this._super.apply(this, arguments);
      //this.changeset = new Changeset(this.get('model'), lookupValidator(IllustrationValidator), IllustrationValidator);
      this.changeset = new _emberChangeset.default(this.get("model"), _illustration.default);
    },

    actions: {
      updateValue: function updateValue(key, value) {
        this.get("changeset").set(key, value);
        //this.get('changeset').validate();
      },
      submit: function submit() {
        var _this = this;

        var self = this;

        if (!this.get("isValidYrs") || !this.get("isValidAddPayment")) {
          return false;
        }

        if (this.get("changeset.isPristine") && "" === Ember.$("#pasteTextArea-field").val()) {
          this.get("flashMessages").info("Nothing changed...");
        } else if (this.get("changeset.isInvalid")) {
          this.get("flashMessages").danger("Please fix errors first...");
        } else {
          this.get("flashMessages").info("Submitting changes...");
          this.set("changeset.data.rawPolicyData", Ember.$("#pasteTextArea-field").val());

          this.get("changeset").save().then(function () {
            self.get("flashMessages").success("Illustration Updated...");
            self.$("#illustration-link").click();
          }).catch(function (response) {
            // todo: check if this is an xhr object before displaying the next message
            self.get("flashMessages").danger("Unable to update Illustration... please try again...");
            self.get("sentryLogger").captureException(new Error("Illustration Update Failed: " + response));
            if (response.hasOwnProperty("errors")) {
              response.errors.forEach(function (error) {
                _this.get("changeset").pushErrors(error[0], error[1]);
              });
            }
          });
        }
      },
      rollback: function rollback() {
        return this.get("changeset").rollback();
      }
    }
  });
});