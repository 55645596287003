define('illustrify/transitions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    this.transition(this.fromRoute('index'), this.toRoute('register'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('index'), this.toRoute('forgotpw'), this.use('toRight'), this.reverse('toLeft'));
    this.transition(this.fromRoute('forgotpw'), this.toRoute('register'), this.use('toRight'), this.reverse('toLeft'));
    this.transition(this.hasClass('isEditing'), this.use('crossFade'));
    this.transition(this.hasClass('isPasted'), this.use('crossFade'));
  };
});