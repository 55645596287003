define('illustrify/validations/illustration', ['exports', 'ember-changeset-validations/validators', 'illustrify/validators/validate-custom-number'], function (exports, _validators, _validateCustomNumber) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    lender: (0, _validators.validatePresence)(true),
    policy: (0, _validators.validatePresence)(true),
    parsedPolicy: (0, _validators.validatePresence)(true),
    customerAge: [(0, _validators.validatePresence)(true), (0, _validateCustomNumber.default)({ gte: 'customerRec.currentAge' })],
    moneyTenThirtyFive: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ gte: 0, integer: true, message: 'Must be whole number, greater than or equal to 0.' })],
    lowPointYearOne: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ gte: 0, integer: true, message: 'Must be whole number, greater than or equal to 0.' })],
    lowPointYearTwo: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ gte: 0, integer: true, message: 'Must be whole number, greater than or equal to 0.' })],
    carrier: (0, _validators.validatePresence)(true),
    firstYearLiborRate: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ gte: 0, lte: 1, message: 'Must be between 0 and 100' })],
    csvCollateralStressRate: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ gte: 0, lte: 1, message: 'Must be between 0 and 100' })],
    gpulPremium: (0, _validators.validateNumber)({ gte: 0, integer: true }),
    gpulFaceAmount: (0, _validators.validateNumber)({ gte: 0, integer: true }),
    clientPaidPremium: (0, _validators.validateNumber)({ gte: 0, integer: true }),
    loanPayoffAmount: (0, _validators.validateNumber)({ gte: 0, integer: true }),
    loanPayoffYear: [(0, _validators.validateNumber)({ integer: true }), (0, _validateCustomNumber.default)({ gt: 'yearsToPayInterest' })],
    lenderLocRate: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ gte: 0, lte: 1, message: 'Must be between 0 and 100' })],
    rcaInterestRate: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ gte: 0, lte: 1, message: 'Must be between 0 and 100' })],
    stressTestRate: (0, _validators.validatePresence)(true),
    targetPremium: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ gte: 0, integer: true, message: 'Must be a whole number, 0 or greater.' })],
    yearsToPayInterest: [(0, _validators.validateNumber)({ gte: 1, lte: 40, integer: true }), (0, _validateCustomNumber.default)({ lt: 'loanPayoffYear' })]
  };
});