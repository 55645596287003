define('illustrify/routes/secure/client', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = Ember.Route.extend({
    setupData: service(),
    beforeModel: function beforeModel() {
      return this.get('setupData').load();
    }
  });
});