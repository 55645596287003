define('illustrify/routes/secure/client/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = Ember.Route.extend({
    currentUser: service(),
    model: function model() {
      return this.store.createRecord('client', {
        agent: this.get('currentUser.userId'),
        firstName: null,
        lastName: null,
        addressState: null,
        birthDate: null,
        svFirstName: null,
        svLastName: null,
        svBirthDate: null
      });
    }
  });
});