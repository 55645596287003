define('illustrify/components/logo-upload', ['exports', 'illustrify/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = Ember.Component.extend({
    session: service(),
    token: Ember.computed('session', function () {
      return undefined.get('session.data.authenticated.token');
    }),
    uploadEndpoint: function () {
      var logoUrl = this.get('logoUrl');
      return _environment.default.APP.API_HOST + '/' + _environment.default.APP.API_NAMESPACE + '/' + logoUrl + '/logo/';
    }.property('logoUrl'),
    acceptedFiles: 'image/*',
    headers: {
      'Authorization': 'JWT ' + undefined.token
    }
  });
});