define('illustrify/routes/secure/illustration/view', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      return Ember.RSVP.hash({
        illustration: this.store.findRecord('illustration', params.illustration_id, { reload: true }),
        policyData: this.store.peekAll('policyDatum'),
        policyAltData: this.store.peekAll('policyAltDatum'),
        policyCompData: this.store.peekAll('policyCompDatum'),
        agents: this.store.findAll('agent')
      });
    },

    actions: {
      willTransition: function willTransition(transition) {
        if (this.get('controller.changeset.isDirty')) {
          if (!confirm('You have unsaved changes.  Are you sure you want to leave this page?')) {
            transition.abort();
          }
        }
      }
    }
  });
});