define('illustrify/models/policy-comp-datum', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    year: _emberData.default.attr('number'),
    age: _emberData.default.attr('number'),
    income: _emberData.default.attr('number'),
    premiumAmount: _emberData.default.attr('number'),
    cashSurrenderValue: _emberData.default.attr('number'),
    deathBenefit: _emberData.default.attr('number')
  });
});