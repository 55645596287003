define('illustrify/components/form-client', ['exports', 'ember-changeset', 'illustrify/validations/client', 'ember-changeset-validations'], function (exports, _emberChangeset, _client, _emberChangesetValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service;
  exports.default = Ember.Component.extend({
    setupData: service(),
    flashMessages: service(),
    minDate: computed(function () {
      return moment.utc().subtract(100, 'years').toDate();
    }),
    maxDate: computed(function () {
      return moment.utc().subtract(1, 'years').toDate();
    }),
    yearRange: computed(function () {
      return moment.utc().subtract(100, 'years').year() + ',' + moment.utc().subtract(1, 'years').year();
    }),
    defaultDate: computed(function () {
      return moment.utc().subtract(45, 'years').toDate();
    }),
    disableSave: computed('changeset.{isPristine,isInvalid}', function () {
      return this.get('changeset.isInvalid') || this.get('changeset.isPristine');
    }),
    stateSelector: computed('changeset.addressState', 'setupData.states', function () {
      var state = this.get('changeset.addressState');
      return state ? _.find(this.get('setupData.states'), { 'id': state }) : null;
    }),
    genderSelector: computed('changeset.gender', 'setupData.genders', function () {
      var gender = this.get('changeset.gender');
      return gender ? _.find(this.get('setupData.genders'), { 'id': gender }) : null;
    }),
    raceSelector: computed('changeset.race', 'setupData.races', function () {
      var race = this.get('changeset.race');
      return race ? _.find(this.get('setupData.races'), { 'id': race }) : null;
    }),
    rateClassSelector: computed('changeset.rateclass', 'setupData.rateclasses', function () {
      var rateclass = this.get('changeset.rateclass');
      return rateclass ? _.find(this.get('setupData.rateclasses'), { 'id': rateclass }) : null;
    }),
    init: function init() {
      this._super.apply(this, arguments);
      var model = this.get('clientData');
      this.changeset = new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(_client.default), _client.default);
      if (moment.utc(this.changeset.birthDate, 'MM-DD-YYYY').isValid()) {
        this.changeset.selectedAge = moment.utc().diff(moment.utc(this.changeset.birthDate, 'MM-DD-YYYY'), 'years');
      }
    },

    actions: {
      setBirthDate: function setBirthDate(value) {
        if (moment.utc(value, 'MM-DD-YYYY').isValid()) {
          this.set('changeset.birthDate', value);
        } else {
          this.get('flashMessages').danger('The date entered is not a valid birth date.');
        }
      },
      setSvBirthDate: function setSvBirthDate(value) {
        if (moment.utc(value, 'MM-DD-YYYY').isValid()) {
          this.set('changeset.svBirthDate', value);
        } else {
          this.get('flashMessages').danger('The date entered is not a valid survivorship bith date.');
        }
      },
      setState: function setState(value) {
        this.set('changeset.addressState', value.id);
      },
      setRace: function setRace(value) {
        this.set('changeset.race', value.id);
      },
      setGender: function setGender(value) {
        this.set('changeset.gender', value.id);
      },
      setRateClass: function setRateClass(value) {
        this.set('changeset.rateclass', value.id);
      },
      setFieldValue: function setFieldValue(key, value) {
        this.set('changeset.' + key, value);
      },
      submit: function submit(changeset) {
        if (changeset.get('isPristine')) {
          this.get('flashMessages').info('Nothing Changed');
        } else {
          var self = this;
          changeset.save().then(function (response) {
            self.get('flashMessages').success('Saved...');
            self.sendAction('goToClientView', response.id);
          }).catch(function (response) {
            if (_.has(response, 'errors')) {
              response.errors.forEach(function (error) {
                changeset.pushErrors(error[0], error[1]);
              });
            }
          });
        }
      },
      rollback: function rollback(changeset) {
        return changeset.rollback();
      }
    }
  });
});