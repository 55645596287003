define('illustrify/services/setup-data', ['exports', 'illustrify/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = Ember.Service.extend({
    session: service(),
    store: service(),
    sentryLogger: service(),
    states: [],
    roles: [],
    lenders: [],
    policies: [],
    carriers: [],
    stressRates: [],
    genders: [],
    races: [],
    rateclasses: [],
    load: function load() {
      var _this = this;

      if (this.get('session.isAuthenticated')) {
        var that = this;
        var apiUrl = _environment.default.APP.API_HOST + '/' + _environment.default.APP.API_NAMESPACE + '/setup/';
        var requestObject = {
          url: apiUrl,
          type: 'GET',
          dataType: 'json',
          success: function success(setup) {
            that.set('states', setup.states);
            that.set('roles', setup.roles);
            that.set('programTypes', setup.programTypes);
            that.set('lenders', setup.lenders);
            that.set('policies', setup.policies);
            that.set('carriers', setup.carriers);
            that.set('stressRates', setup.stressRates);
            that.set('genders', setup.genders);
            that.set('races', setup.races);
            that.set('rateclasses', setup.rateclasses);
          },
          error: function error(resp) {
            if (resp.status === 401) {
              _this.get('session').invalidate();
            }
          },
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'JWT ' + _this.get('session.data.authenticated.token'));
          }
        };
        return Ember.$.ajax(requestObject);
      }
    }
  });
});