define('illustrify/validations/client', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    firstName: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ min: 2, max: 120 })],
    lastName: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ min: 2, max: 120 })],
    birthDate: [(0, _validators.validatePresence)(true)],
    addressState: (0, _validators.validatePresence)(true),
    svFirstName: [(0, _validators.validateLength)({ allowBlank: true, max: 120 })],
    svLastName: [(0, _validators.validateLength)({ allowBlank: true, max: 120 })],
    svBirthDate: [(0, _validators.validateLength)({ allowBlank: true })]
  };
});