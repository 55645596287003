define('illustrify/models/agency', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    address: _emberData.default.attr('string'),
    phoneNumber: _emberData.default.attr('string', { defaultValue: '' }),
    created: _emberData.default.attr('date'),
    logoData: _emberData.default.attr('string'),
    canInvite: _emberData.default.attr('boolean'),
    canAddClient: _emberData.default.attr('boolean'),
    canAddIllustration: _emberData.default.attr('boolean'),
    isActive: _emberData.default.attr('boolean')
  });
});