define('illustrify/components/form-resetpw', ['exports', 'ember-changeset', 'illustrify/validations/resetpw', 'ember-changeset-validations', 'illustrify/config/environment'], function (exports, _emberChangeset, _resetpw, _emberChangesetValidations, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service;
  exports.default = Ember.Component.extend({
    flashMessages: service(),
    password: null,
    token: null,
    confirm: null,
    errorMessage: null,
    showResetLink: false,
    showLoginLink: false,
    submitDisabled: computed('changeset.{isInvalid,isPristine}', function () {
      return this.get('changeset.isInvalid') || this.get('changeset.isPristine');
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.changeset = new _emberChangeset.default(this, (0, _emberChangesetValidations.default)(_resetpw.default), _resetpw.default);
    },

    actions: {
      submit: function submit() {
        var _this = this;

        var apiUrl = _environment.default.APP.API_HOST + '/' + _environment.default.APP.API_NAMESPACE + '/reset/';
        var data = {
          password: this.get('changeset.password'),
          token: this.get('token')
        };
        var requestObject = {
          url: apiUrl,
          method: 'POST',
          data: JSON.stringify(data),
          contentType: 'application/json',
          dataType: 'json',
          success: function success() {
            _this.toggleProperty('showLoginLink');
            _this.get('flashMessages').success('Password reset...');
          },
          error: function error(res) {
            if (_.has(res, 'responseJSON.error')) {
              _this.toggleProperty('showResetLink');
              _this.set('errorMessage', res.responseJSON.error);
            } else {
              console.log(res);
              _this.set('errorMessage', 'Unable to reset password. Please try again later.');
            }
          }
        };
        return Ember.$.post(requestObject);
      }
    }
  });
});