define('illustrify/validations/illustration_config', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    lender: (0, _validators.validatePresence)(true),
    description: (0, _validators.validateLength)({ max: 40 }),
    moneyTenThirtyFive: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ gte: 0, integer: true, message: 'Must be whole number, greater than 0.' })],
    // advancedDrawFee: validate,
    // arrangementFeeRate: validate,
    // capAdvancedDrawFee: validate,
    // capArrangementFee: validate,
    carrier: (0, _validators.validatePresence)(true),
    // csvCollateralValueRate: validate,
    // deathBenefit: validate,
    firstYearLiborRate: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ gte: 0, lte: 1, message: 'Must be between 0 and 100' })],
    // fixedInterestPayment: validate,
    // fmPct: validate,
    // gplPremium: validate,
    // indexedUl: validate,
    // lenderAddtlInterestCollateralRate: validate,
    // lenderCollateralValueRate: validate,,
    // lenderLocFee: validate,
    lenderLocRate: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ gte: 0, lte: 1, message: 'Must be between 0 and 100' })],
    // payFixedInterest: validate,
    // payInterestAnnually: validate,
    rcaInterestRate: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ gte: 0, lte: 1, message: 'Must be between 0 and 100' })],
    // selectIraAdvantage: validate,
    stressTestRate: (0, _validators.validatePresence)(true),
    targetPremium: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ gte: 0, integer: true, message: 'Must be a whole number, 0 or greater.' })],
    yearsToPayInterest: (0, _validators.validateNumber)({ gte: 5, lte: 40, integer: true, message: 'Please select a range between 5 and 40' }),
    computedLoanInterest: [(0, _validators.validatePresence)(true),
    // todo set this to gte 2k + fixed interest payment
    (0, _validators.validateNumber)({ gte: 2000.00, message: 'Value entered is too low' })]

  };
});