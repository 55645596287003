define('illustrify/components/formatted-input-field', ['exports', 'numeral'], function (exports, _numeral) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Component.extend({
    valueMultiplier: 1,
    value: 0,
    displayFormat: '0.0',
    fieldValue: computed('value', 'displayFormat', function () {
      return (0, _numeral.default)(this.get('value').value).format(this.get('displayFormat'));
    }),
    actions: {
      focusAction: function focusAction() {
        this.set('fieldValue', this.get('value').value * this.get('valueMultiplier'));
      },
      unFocusAction: function unFocusAction(e) {
        var newValue = e.target.value / this.get('valueMultiplier');
        this.set('fieldValue', (0, _numeral.default)(newValue).format(this.get('displayFormat')));
        this.sendAction('updateAction', newValue);
      }
    }
  });
});