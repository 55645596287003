define('illustrify/controllers/secure/client/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      goToClientView: function goToClientView(id) {
        this.transitionToRoute('secure.client.view', id);
      }
    }
  });
});