define('illustrify/routes/secure/agents/view', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = Ember.Route.extend({
    flashMessages: service(),
    model: function model(params) {
      return this.store.findRecord('agent', params.agent_id);
    },

    actions: {
      submitUpdate: function submitUpdate(changeset) {
        var _this = this;

        console.log('changeset', changeset);
        if (changeset.get('isPristine')) {
          this.get('flashMessages').info('Nothing Changed');
        } else {
          var self = this;
          changeset.save().then(function () {
            self.get('flashMessages').success('Saved...');
          }).catch(function (response) {
            _this.get('flashMessages').danger(response.error);
            if (response.hasOwnProperty('errors')) {
              response.errors.forEach(function (error) {
                changeset.pushErrors(error[0], error[1]);
              });
            }
          });
        }
      }
    }
  });
});