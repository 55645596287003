define("illustrify/components/form-illustration", ["exports", "ember-changeset", "ember-changeset-validations", "illustrify/validations/new_illustration", "illustrify/config/environment"], function (exports, _emberChangeset, _emberChangesetValidations, _new_illustration, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service;
  exports.default = Ember.Component.extend({
    setupData: service(),
    session: service(),
    store: service(),
    lenderRate: service(),
    flashMessages: service(),
    sentryLogger: service(),
    parsingData: false,
    parsedData: null,
    parsedAltData: null,
    parsedCompData: null,
    acceptedFiles: ".csv, .xls, .xlsx",
    currentStep: 1,
    availPolicies: computed("setupData.policies", "isSmallCasePlatform", function () {
      return this.get("isSmallCasePlatform") ? _.filter(this.get("setupData.policies"), { isScp: true }) : _.filter(this.get("setupData.policies"), { isTraditional: true });
    }),
    wizardSteps: computed("isSmallCasePlatform", function () {
      var baseSteps = [{ step: 1, id: "1", name: "Basic Information" }, { step: 2, id: "2", name: "Illustration Data" }];
      if (this.get("isSmallCasePlatform")) {
        baseSteps.push({ step: 3, id: "3", name: "Alternate CSV Data" });
        baseSteps.push({ step: 4, id: "4", name: "Comparison Data" });
      }
      return baseSteps;
    }),
    isStep1: computed("currentStep", function () {
      return this.get("currentStep") === 1;
    }),
    isStep2: computed("currentStep", function () {
      return this.get("currentStep") === 2;
    }),
    isStep3: computed("currentStep", function () {
      return this.get("currentStep") === 3;
    }),
    isStep4: computed("currentStep", function () {
      return this.get("currentStep") === 4;
    }),
    isSmallCasePlatform: computed.equal("changeset.programType", "insured_ret"),
    selectedPolicy: computed("changeset.policy", "setupData.policies", {
      get: function get() {
        return _.find(this.get("setupData.policies"), {
          id: this.get("changeset.policy")
        });
      },
      set: function set(key, value) {
        if (value.defaultLender !== null && value.defaultLender !== this.get("changeset.lender")) {
          var selectedLender = _.find(this.get("setupData.lenders"), {
            id: value.defaultLender
          });
          this.send("selectLenderRecord", selectedLender);
          // this.get('flashMessages').info('Lender Changed...');
        }
        this.send("resetParsedData");
        this.send("setFieldValue", "policy", value.id);
        return value;
      }
    }),
    selectedProgram: computed("changeset.programType", "setupData.programTypes", {
      get: function get() {
        return _.find(this.get("setupData.programTypes"), {
          id: this.get("changeset.programType")
        });
      },
      set: function set(key, value) {
        this.send("setProgramType", value.id);
        return value;
      }
    }),
    selectedClient: computed("changeset.customer", "clients", {
      get: function get() {
        var client_id = this.get("changeset.customer");
        return client_id ? this.get("store").peekRecord("client", client_id) : null;
      },
      set: function set(key, value) {
        this.send("setFieldValue", "customer", value.id);
        return value;
      }
    }),
    clientData: computed.readOnly("selectedClient", function () {
      return this.get("selectedClient");
    }),
    selectedLender: computed("changeset.lender", "setupData.lenders", {
      get: function get() {
        return _.find(this.get("setupData.lenders"), {
          id: this.get("changeset.lender")
        });
      },
      set: function set(key, value) {
        var _this = this;

        this.get("lenderRate").firstYearRate(value.id).then(function (firstYearRate) {
          _this.send("setFieldValue", "firstYearLiborRate", firstYearRate);
          // this.get('flashMessages').info('First Year Rate Updated.');
        });
        // .catch((errorMessage) => {
        //   this.get('flashMessages').danger(errorMessage);
        // });
        this.send("setFieldValue", "lender", value.id);
        return value;
      }
    }),
    selectedStressRate: computed("changeset.stressTestRate", "setupData.stressRates", {
      get: function get() {
        return _.find(this.get("setupData.stressRates"), {
          id: this.get("changeset.stressTestRate")
        });
      },
      set: function set(key, value) {
        this.send("setFieldValue", "stressTestRate", value.id);
        return value;
      }
    }),
    canGoStep2: computed("selectedPolicy", "selectedLender", "selectedClient", "isSmallCasePlatform", function () {
      return !(this.get("selectedPolicy") && this.get("selectedLender") && this.get("selectedClient"));
    }),
    canGoStep3: computed("canGoStep2", "parsedData", function () {
      return this.get("canGoStep2") && !this.get("parsedData");
    }),
    canGoStep4: computed("canGoStep3", "parsedAltData", function () {
      return this.get("canGoStep3") && !this.get("parsedAltData");
    }),
    disableSubmit: computed.or("changeset.{isInvalid,isPristine}"),
    fileSending: computed("session.data.authenticated.token", function () {
      var self = this;
      return function (file, xhr) {
        xhr.setRequestHeader("Accept", "*/*");
        xhr.setRequestHeader("Authorization", "JWT " + self.get("session.data.authenticated.token"));
      };
    }),
    fileUploadSuccess: computed("processParseSuccess", function () {
      var self = this;
      return function (file, resp) {
        self.processParseSuccess(resp);
        Dropzone.forElement("#my-drop-zone").removeAllFiles();
      };
    }),
    fileAltUploadSuccess: computed("processParseSuccess", function () {
      var self = this;
      return function (file, resp) {
        self.processParseAltSuccess(resp);
        Dropzone.forElement("#alt-scp-drop-zone").removeAllFiles();
      };
    }),
    fileCompUploadSuccess: computed("processParseSuccess", function () {
      var self = this;
      return function (file, resp) {
        self.processParseCompSuccess(resp);
        Dropzone.forElement("#comp-scp-drop-zone").removeAllFiles();
      };
    }),
    fileUploadError: computed("processParseError", function () {
      var self = this;
      return function (file, errorMsg, resp) {
        self.processParseError(resp);
        Dropzone.forElement("#my-drop-zone").removeAllFiles();
      };
    }),
    fileAltUploadError: computed("processParseError", function () {
      var self = this;
      return function (file, errorMsg, resp) {
        self.processParseAltError(resp);
        Dropzone.forElement("#alt-scp-drop-zone").removeAllFiles();
      };
    }),
    fileCompUploadError: computed("processParseError", function () {
      var self = this;
      return function (file, errorMsg, resp) {
        self.processParseCompError(resp);
        Dropzone.forElement("#comp-scp-drop-zone").removeAllFiles();
      };
    }),
    uploadEndpoint: computed("selectedPolicy.id", function () {
      var self = this;
      return function () {
        return _environment.default.APP.API_HOST + "/" + _environment.default.APP.API_NAMESPACE + "/parser_v2/" + self.get("selectedPolicy.id") + "/";
      };
    }),
    uploadEndpointAlt: computed("selectedPolicy.id", function () {
      var self = this;
      return function () {
        return _environment.default.APP.API_HOST + "/" + _environment.default.APP.API_NAMESPACE + "/parser_v2/" + self.get("selectedPolicy.id") + "/altcsv/";
      };
    }),
    uploadEndpointComp: computed("selectedPolicy.id", function () {
      var self = this;
      return function () {
        return _environment.default.APP.API_HOST + "/" + _environment.default.APP.API_NAMESPACE + "/parser_v2/" + self.get("selectedPolicy.id") + "/compcsv/";
      };
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.changeset = new _emberChangeset.default(this.get("model"), (0, _emberChangesetValidations.default)(_new_illustration.default), _new_illustration.default);
      this.set("changeset.altIncomeAmount", 0);
      this.set("changeset.altIncomeStartYear", 21);
    },

    /*
    didRender() {
      this._super(...arguments);
      this.$('#pasteTextAreaComp-field').one(
        'paste',
        _.debounce(
          function (e) {
            const content = e.target.value;
            this.procPastedDataComp(content, e);
          }.bind(this),
          500
        )
      );
    },
    */
    processParseSuccess: function processParseSuccess(resp) {
      this.get("flashMessages").success("Parsed");
      this.set("parsingData", false);
      this.set("changeset.parsedPolicy", resp.id);
      this.set("parsedData", resp.data["rows"]);
      this.set("changeset.lowPointYearOne", resp.id["lowPointYearOne"] || 0);
      this.set("changeset.lowPointYearTwo", resp.id["lowPointYearTwo"] || 0);
    },
    processParseAltSuccess: function processParseAltSuccess(resp) {
      this.get("flashMessages").success("Parsed");
      this.set("parsingAltData", false);
      this.set("parsingData", false);
      this.set("changeset.parsedPolicyAlt", resp.id);
      this.set("parsedAltData", resp.data["rows"]);
    },
    processParseCompSuccess: function processParseCompSuccess(resp) {
      this.get("flashMessages").success("Parsed");
      this.set("parsingCompData", false);
      this.set("parsingAltData", false);
      this.set("parsingData", false);
      this.set("changeset.parsedPolicyComp", resp.id);
      this.set("parsedCompData", resp.data["rows"]);
    },
    processParseError: function processParseError(resp) {
      if (resp.status === 401) {
        this.get("session").invalidate();
      }
      this.set("parsingData", false);
    },

    actions: {
      procPastedData: function procPastedData(data) {
        var _this2 = this;

        this.get("flashMessages").info("Parsing");
        if (!this.get("parsingData")) {
          this.set("parsingData", true);
          var requestObject = {
            url: this.get("uploadEndpoint")(),
            type: "POST",
            data: { policyData: data },
            dataType: "json",
            success: function success(resp) {
              _this2.send("setFieldValue", "rawPolicyData", "");
              _this2.processParseSuccess(resp);
            },
            error: function error(resp) {
              _this2.send("setFieldValue", "rawPolicyData", "");
              _this2.get("flashMessages").danger("Parsing Failed");
              _this2.processParseError(resp);
            },
            beforeSend: function beforeSend(xhr) {
              xhr.setRequestHeader("Authorization", "JWT " + _this2.get("session.data.authenticated.token"));
            }
          };
          Ember.$.ajax(requestObject);
        }
      },
      procPastedDataComp: function procPastedDataComp(data) {
        var _this3 = this;

        this.get("flashMessages").info("Parsing");
        if (!this.get("parsingData")) {
          this.set("parsingData", true);
          var requestObject = {
            url: this.get("uploadEndpointComp")(),
            type: "POST",
            data: { policyData: data },
            dataType: "json",
            success: function success(resp) {
              _this3.send("setFieldValue", "rawPolicyCompData", "");
              _this3.processParseCompSuccess(resp);
            },
            error: function error(resp) {
              _this3.send("setFieldValue", "rawPolicyCompData", "");
              _this3.get("flashMessages").danger("Parsing Failed");
              _this3.processParseError(resp);
            },
            beforeSend: function beforeSend(xhr) {
              xhr.setRequestHeader("Authorization", "JWT " + _this3.get("session.data.authenticated.token"));
            }
          };

          Ember.$.ajax(requestObject);
        }
      },
      procPastedDataAlt: function procPastedDataAlt(data) {
        var _this4 = this;

        this.get("flashMessages").info("Parsing");
        if (!this.get("parsingData")) {
          this.set("parsingData", true);
          var requestObject = {
            url: this.get("uploadEndpointAlt")(),
            type: "POST",
            data: { policyData: data },
            dataType: "json",
            success: function success(resp) {
              _this4.send("setFieldValue", "rawPolicyAltData", "");
              _this4.processParseAltSuccess(resp);
            },
            error: function error(resp) {
              _this4.send("setFieldValue", "rawPolicyAltData", "");
              _this4.get("flashMessages").danger("Parsing Failed");
              _this4.processParseError(resp);
            },
            beforeSend: function beforeSend(xhr) {
              xhr.setRequestHeader("Authorization", "JWT " + _this4.get("session.data.authenticated.token"));
            }
          };
          Ember.$.ajax(requestObject);
        }
      },
      setProgramType: function setProgramType(value) {
        if (value === "insured_ret") {
          this.send("setFieldValue", "policy", _.find(this.get("setupData.policies"), { scpDefault: true }).id);
          this.send("setFieldValue", "lender", _.find(this.get("setupData.lenders"), { scpDefault: true }).id);
        } else {
          this.send("setFieldValue", "policy", null);
          this.send("setFieldValue", "lender", _.find(this.get("setupData.lenders"), { default: true }).id);
        }
        this.send("setFieldValue", "programType", value);
      },
      selectRecord: function selectRecord(key, value) {
        this.send("setFieldValue", key, value.id);
      },
      setFieldValue: function setFieldValue(key, value) {
        this.get("changeset").set(key, value);
      },
      selectLenderRecord: function selectLenderRecord(value) {
        // this.get('lenderRate').firstYearRate(value.id).then(firstYearRate => {
        //   this.send('setFieldValue', 'firstYearLiborRate', firstYearRate);
        // this.get('flashMessages').info('First Year Rate Updated.');
        // });
        // .catch((errorMessage) => {
        //   this.get('flashMessages').danger(errorMessage);
        // });
        this.send("setFieldValue", "lender", value.id);
      },
      nextStep: function nextStep() {
        this.set("currentStep", this.get("currentStep") + 1);
      },
      prevStep: function prevStep() {
        this.set("currentStep", this.get("currentStep") - 1);
      },
      submit: function submit() {
        var changeset = this.get("changeset");
        if (changeset.get("isPristine")) {
          changeset.validate();
        } else {
          var self = this;
          changeset.save().then(function (response) {
            self.sendAction("createAction", response.id);
          }).catch(function (response) {
            if (response.hasOwnProperty("errors")) {
              _.each(response.errors, function (messages, key) {
                messages.map(function (message) {
                  changeset.addError(key, message);
                });
              });
            }
            self.get("flashMessages").danger("Unable to create illustration. Please try again...");
            self.get("sentryLogger").captureException(new Error("unable to create illustration: " + response));
          });
        }
      },
      resetParsedData: function resetParsedData() {
        this.send("setFieldValue", "parsedPolicy", null);
        switch (this.get("currentStep")) {
          case 2:
            this.set("parsedData", null);
            break;
          case 3:
            this.set("parsedAltData", null);
            break;
          case 4:
            this.set("parsedCompData", null);
            break;
        }
      }
    }
  });
});