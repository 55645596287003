define('illustrify/components/form-login', ['exports', 'ember-changeset', 'illustrify/validations/login', 'ember-changeset-validations'], function (exports, _emberChangeset, _login, _emberChangesetValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service;
  exports.default = Ember.Component.extend({
    session: service(),
    flashMessages: service(),
    identification: null,
    password: null,
    submitDisabled: computed('changeset.{isInvalid,isPristine}', function () {
      return this.get('changeset.isInvalid') || this.get('changeset.isPristine');
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.changeset = new _emberChangeset.default(this, (0, _emberChangesetValidations.default)(_login.default), _login.default);
    },

    actions: {
      authenticate: function authenticate() {
        var _this = this;

        var credentials = this.get('changeset').getProperties('identification', 'password');
        this.get('session').authenticate('authenticator:jwt', credentials).catch(function () {
          _this.get('flashMessages').danger('Unable to login with provided credentials.');
        });
      }
    }
  });
});