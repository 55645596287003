define('illustrify/routes/secure/account', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = Ember.Route.extend({
    currentUser: service(),
    savingAgent: false,
    model: function model() {
      return this.store.findRecord('agent', this.get('currentUser.userId'));
    },

    actions: {
      submitUpdate: function submitUpdate(changeset) {
        var _this = this;

        if (changeset.get('isPristine')) {
          this.get('flashMessages').info('Nothing Changed');
        } else {
          this.set('savingAgent', true);
          var self = this;
          changeset.save().then(function () {
            self.get('currentUser').load();
            self.get('flashMessages').success('Saved...');
          }).catch(function (response) {
            _this.get('flashMessages').danger(response.error);
            if (response.hasOwnProperty('errors')) {
              response.errors.forEach(function (error) {
                changeset.pushErrors(error[0], error[1]);
              });
            }
          }).finally(function () {
            _this.set('savingAgent', false);
          });
        }
      }
    }
  });
});