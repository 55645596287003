define('illustrify/validations/agent', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    firstName: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ min: 2, max: 50 })],
    lastName: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ min: 2, max: 50 })],
    email: [(0, _validators.validatePresence)(true), (0, _validators.validateFormat)({ type: 'email' })],
    role: [(0, _validators.validatePresence)(true)]
  };
});