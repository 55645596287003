define('illustrify/adapters/user-agency', ['exports', 'ember-data', 'illustrify/config/environment'], function (exports, _emberData, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = _emberData.default.Adapter.extend({
    session: service(),
    queryRecord: function queryRecord() {
      var _this = this;

      var requestObject = {
        url: _environment.default.APP.API_HOST + '/' + _environment.default.APP.API_NAMESPACE + '/agencies/me/',
        type: 'GET',
        dataType: 'json',
        success: function success(resp) {
          return resp;
        },
        error: function error(resp) {
          return resp;
        },
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'JWT ' + _this.get('session.data.authenticated.token'));
        }
      };
      return Ember.$.getJSON(requestObject);
    }
  });
});