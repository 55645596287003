define('illustrify/validations/login', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    identification: [(0, _validators.validateFormat)({ type: 'email' }), (0, _validators.validatePresence)(true)],
    password: (0, _validators.validatePresence)(true)
  };
});