define('illustrify/components/illustration-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service;
  exports.default = Ember.Component.extend({
    currentAgency: service(),
    globalFilterTemplate: 'components/table-global-search',
    customMessages: computed('currentAgency.canAddIllustration', function () {
      return {
        'addNewPath': 'secure.illustration.index',
        'canAddNew': this.get('currentAgency.canAddIllustration')
      };
    }),
    customClasses: {
      'table': 'table table-condensed table-striped table-hover'
    },
    columns: [{
      "propertyName": "programTypeDisplay",
      'routeName': 'secure.illustration.view',
      "title": "Program"
    }, {
      "propertyName": "customerName",
      'routeName': 'secure.illustration.view',
      "title": "Customer"
    }, {
      "propertyName": "description",
      'routeName': 'secure.illustration.view',
      "title": "Description"
    }, {
      "propertyName": "policyName",
      'routeName': 'secure.illustration.view',
      "title": "Policy"
    }, {
      "propertyName": "lenderName",
      "title": "Lender"
    }, {
      "propertyName": "agentName",
      "title": "Agent"
    }, {
      "propertyName": "createdFormat",
      "title": "Created"
    }, {
      "propertyName": "lastModifiedFormat",
      "title": "Modified"
    }]
  });
});