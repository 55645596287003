define('illustrify/components/search-filter-bar', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    searchValue: '',
    sortGroupValue: function () {
      return this.get('startSortBy') || 'asc';
    }.property('startSortBy'),
    filterGroupValue: true,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.get('filter')('').then(function (results) {
        return _this.set('results', results);
      });
    },

    actions: {
      addNew: function addNew() {
        this.get('addNew')();
      },
      filterMeBy: function filterMeBy() {
        console.log('filter request');
      },
      sortMeBy: function sortMeBy(value) {
        this.sendAction('sortAction', value);
      },
      handleFilterEntry: function handleFilterEntry() {
        var _this2 = this;

        var filterInputValue = this.get('searchValue');
        var filterAction = this.get('filter');
        console.log('filtering', filterInputValue);
        filterAction(filterInputValue).then(function (filterResults) {
          return _this2.set('results', filterResults);
        });
      }
    }
  });
});