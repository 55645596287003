define('illustrify/models/quick-view-data', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    arrangementFee: _emberData.default.attr('number'),
    interestPaidTotal: _emberData.default.attr('number'),
    maxInterestRate: _emberData.default.attr('number'),
    maxNetDb: _emberData.default.attr('number'),
    maxOutlay: _emberData.default.attr('number'),
    minInterestRate: _emberData.default.attr('number'),
    minNetDb: _emberData.default.attr('number'),
    minOutlay: _emberData.default.attr('number'),
    policyLoanAmountTotal: _emberData.default.attr('number'),
    policyLoanAmount_5yr: _emberData.default.attr('number'),
    policyLoanAmount_10yr: _emberData.default.attr('number'),
    policyLoanAmount_15yr: _emberData.default.attr('number'),
    premiumsTotal: _emberData.default.attr('number'),
    totalTotalOutlay: _emberData.default.attr('number')
  });
});