define('illustrify/components/field-birthdate-age', ['exports', 'numeral'], function (exports, _numeral) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Component.extend({
    minDate: computed(function () {
      return moment.utc().subtract(100, 'years').toDate();
    }),
    maxDate: computed(function () {
      return moment.utc().toDate();
    }),
    yearRange: computed(function () {
      return moment.utc().subtract(100, 'years').year() + ',' + moment.utc().subtract(1, 'years').year();
    }),
    dob_label: computed('labe', function () {
      if (_.isUndefined(this.get('label'))) {
        return 'Birth Date';
      } else {
        return this.get('label');
      }
    }),
    // birthDateDate: computed('birthDate', function() {
    //   if (!_.isUndefined(this.get('birthDate'))) {
    //     const birthdate = moment.utc(this.get('birthDate'), 'MM-DD-YYYY')
    //     this.set('selectedAge', numeral(moment.utc().diff(birthdate, 'years', true)).format('0.00'));
    //     return birthdate;
    //   }
    //   return null;
    // }),
    init: function init() {
      this._super.apply(this, arguments);
      var birthdate = moment.utc(this.get('birthDate'), 'MM-DD-YYYY');
      this.model = {
        birthDate: null,
        selectedAge: null
      };
      if (!_.isUndefined(this.get('birthDate'))) {
        this.set('model.birthDate', birthdate);
        this.set('model.selectedAge', (0, _numeral.default)(moment.utc().diff(birthdate, 'years', true)).format('0.00'));
      }
    },

    actions: {
      updateBirthdate: function updateBirthdate(value) {
        if (moment.utc(value).isValid()) {
          this.set('model.birthDate', value);
          var newBirthDate = moment.utc(value).format('MM-DD-YYYY').toString();
          var newAge = (0, _numeral.default)(moment.utc().diff(moment.utc(value), 'years', true)).format('0.00');
          this.set('model.selectedAge', newAge);
          this.sendAction('updateAction', newBirthDate);
        }
      },
      updateAge: function updateAge(value) {
        var newAge = _.toNumber(value.target.value);
        if (_.isNumber(newAge)) {
          var newBirthDate = moment.utc();
          newBirthDate.subtract(newAge, 'years');
          this.set('model.birthDate', newBirthDate);
          this.set('model.selectedAge', newAge);
          this.sendAction('updateAction', newBirthDate.format('MM-DD-YYYY').toString());
        }
        // },
        // change(value, model, property) {
        //   console.log(value, model, property);
        //   if (property === 'selectedAge') {
        //     this.send('updateAge', value)
        //   } else if(property === 'birthDateDate') {
        //     this.send('updateBirthdate', value);
        //   }
      }
    }
  });
});