define('illustrify/components/form-agency', ['exports', 'ember-changeset', 'ember-changeset-validations', 'illustrify/validations/agency', 'illustrify/config/environment'], function (exports, _emberChangeset, _emberChangesetValidations, _agency, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service;
  exports.default = Ember.Component.extend({
    session: service(),
    flashMessages: service(),
    acceptedFiles: 'image/*',
    uploadCancelled: computed(function () {
      return function () {
        Dropzone.forElement('#my-drop-zone').removeAllFiles();
      };
    }),
    uploadFailed: computed(function () {
      var _this = this;

      return function (file, errorMessage) {
        _this.get('flashMessages').danger(errorMessage);
        Dropzone.forElement('#my-drop-zone').removeAllFiles();
      };
    }),
    newLogoUploaded: computed(function () {
      var _this2 = this;

      return function (fileUploaded, resp) {
        _this2.get('flashMessages').success('Saved...');
        _this2.set('agency.logoData', resp.logoData);
        Dropzone.forElement('#my-drop-zone').removeAllFiles();
      };
    }),
    uploadEndpoint: computed(function () {
      return _environment.default.APP.API_HOST + '/' + _environment.default.APP.API_NAMESPACE + '/agencies/logo/';
    }),
    fileSending: computed('session.data.authenticated.token', function () {
      var self = this;
      return function (file, xhr) {
        xhr.setRequestHeader('Accept', '*/*');
        xhr.setRequestHeader('Authorization', 'JWT ' + self.get('session.data.authenticated.token'));
      };
    }),
    init: function init() {
      this._super.apply(this, arguments);
      var model = this.get('agency');
      this.changeset = new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(_agency.default), _agency.default);
    },

    actions: {
      submitUpdate: function submitUpdate(changeset) {
        if (changeset.get('isPristine')) {
          this.get('flashMessages').info('Nothing Changed');
        } else {
          var self = this;
          changeset.save().then(function () {
            self.get('flashMessages').success('Saved...');
          }).catch(function (response) {
            response.errors.forEach(function (error) {
              changeset.pushErrors(error[0], error[1]);
            });
          });
        }
      },
      rollback: function rollback() {
        return this.get('changeset').rollback();
      }
    }
  });
});