define('illustrify/components/form-change-password', ['exports', 'ember-changeset', 'illustrify/validations/security', 'ember-changeset-validations', 'illustrify/config/environment'], function (exports, _emberChangeset, _security, _emberChangesetValidations, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = Ember.Component.extend({
    currentUser: service(),
    session: service(),
    flashMessages: service(),
    init: function init() {
      this._super.apply(this, arguments);
      this.changeset = new _emberChangeset.default(this, (0, _emberChangesetValidations.default)(_security.default), _security.default);
    },

    actions: {
      rollback: function rollback() {
        this.get('changeset').rollback();
      },
      submit: function submit() {
        var _this = this;

        var changeset = this.get('changeset');
        if (changeset.get('passwordCurrent') === changeset.get('passwordNew')) {
          this.get('flashMessages').info('Nothing changed...');
        } else {
          var apiUrl = _environment.default.APP.API_HOST + '/' + _environment.default.APP.API_NAMESPACE + '/agents/security/';
          var data = {
            passwordCurrent: changeset.get('passwordCurrent'),
            passwordNew: changeset.get('passwordNew'),
            passwordNewConfirm: changeset.get('passwordNewConfirm')
          };
          var requestObject = {
            url: apiUrl,
            method: 'PUT',
            data: JSON.stringify(data),
            contentType: 'application/json',
            dataType: 'json',
            success: function success() {
              _this.get('flashMessages').success('Password Chnaged.');
            },
            error: function error(res) {
              if (_.has(res, 'responseJSON.error')) {
                _this.get('flashMessages').danger(res.responseJSON.error);
                for (var key in res.responseJSON.errors) {
                  changeset.addError(key, res.responseJSON.errors[key]);
                }
              } else {
                _this.get('flashMessages').danger('Unable to change password. Please try again later.');
              }
            },
            beforeSend: function beforeSend(xhr) {
              xhr.setRequestHeader('Authorization', 'JWT ' + _this.get('session.data.authenticated.token'));
            }
          };
          return Ember.$.post(requestObject);
        }
      }
    }
  });
});