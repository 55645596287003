define("illustrify/components/illustration-view-output", ["exports", "illustrify/config/environment"], function (exports, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service;
  exports.default = Ember.Component.extend({
    session: service(),
    store: service(),
    currentUser: service(),
    flashMessages: service(),
    isComparisonNotAvailable: computed("changeset.parsedPolicyComp", function () {
      return this.get("changeset.parsedPolicyComp") == null;
    }),
    canSelectAgent: computed("currentUser.{isSuperuser,isManager}", function () {
      return this.get("currentUser.isSuperuser") || this.get("currentUser.isManager");
    }),
    selectedAgent: computed("outputAgent", "agents", function () {
      return this.get("store").findRecord("agent", this.get("outputAgent"));
    }),
    isSmallCasePlatform: computed("changeset.isSmallCasePlatform", function () {
      return this.get("changeset.isSmallCasePlatform");
    }),
    isTraditionalPlatform: computed("changeset.isSmallCasePlatform", function () {
      return !this.get("changeset.isSmallCasePlatform");
    }),
    outputAgent: null,
    pageBankSheet: true,
    pagePFSheet: true,
    pageSummary: true,
    pageGlobalOne: true,
    pagePfVsNf: true,
    pageNfVsF: true,
    pageStressTestBreakdown: true,
    pageComparison: false,
    pageInstructions: false,
    downloadingPDF: false,
    displayLOC: true,
    calculateIrr: false,
    pageScpBrochure: true,
    pageScpBackCover: true,
    pageScpComparison: false,
    pageScpTrustInfo: true,
    init: function init() {
      this._super.apply(this, arguments);
      this.outputAgent = this.get("currentUser").userId;
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      if (this.get("isComparisonNotAvailable")) {
        this.set("pageNfVsF", false);
        this.set("pageScpComparison", false);
      }
    },

    actions: {
      selectRecord: function selectRecord(key, value) {
        this.set("outputAgent", value.id);
      },
      downloadPDFReport: function downloadPDFReport() {
        this.set("downloadingPDF", true);
        var illustr_id = this.get("illustrationId");
        var apiUrl = _environment.default.APP.API_HOST + "/" + _environment.default.APP.API_NAMESPACE + ("/illustrations/" + illustr_id + "/pdf/");
        if (this.get("isSmallCasePlatform")) {
          window.open(apiUrl + "?page_scp_brochure=" + this.pageScpBrochure + "&page_scp_back_cover=" + this.pageScpBackCover + "&page_scp_trust_info=" + this.pageScpTrustInfo + "&page_scp_comparison=" + this.pageScpComparison + "&jwt=" + this.get("session.data.authenticated.token") + "&output_agent=" + this.outputAgent, "_blank", "noreferrer");
          this.set("downloadingPDF", false);
          return true;
        } else {
          window.open(apiUrl + "?jwt=" + this.get("session.data.authenticated.token") + "&output_agent=" + this.outputAgent, "_blank", "noreferrer");
          this.set("downloadingPDF", false);
          return true;
          // const requestObject = {
          //   url: apiUrl,
          //   method: "POST",
          //   data: JSON.stringify(data),
          //   cache: false,
          //   contentType: "application/json",
          //   dataType: "text",
          //   timeout: 0,
          //   headers: {
          //     Authorization:
          //       "JWT " + this.get("session.data.authenticated.token"),
          //   },
          //   success: (res) => {
          //     var data = new Blob([res], { type: "application/pdf" });
          //     window.saveAs(data, `${gen_date}_${customer}_illustration.pdf`);
          //     this.set("downloadingPDF", false);
          //   },
          //   error: (res) => {
          //     if (res.hasOwnProperty("status") && res.status === 401) {
          //       this.get("session").invalidate();
          //     }
          //     this.set("downloadingPDF", false);
          //     this.get("flashMessages").danger(
          //       "Failed to download. Please try again or contact support."
          //     );
          //     console.log(res || "doh, something broke");
          //   },
          // };
          // return Ember.$.post(requestObject);
        }
      }
    }
  });
});