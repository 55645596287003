define('illustrify/routes/application', ['exports', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _applicationRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = Ember.Route.extend(_applicationRouteMixin.default, {
    currentUser: service(),
    currentAgency: service(),
    flashMessages: service(),
    beforeModel: function beforeModel() {
      return Ember.RSVP.hash({
        agency: this._loadCurrentAgency(),
        user: this._loadCurrentUser()
      });
    },
    sessionAuthenticated: function sessionAuthenticated() {
      var _this = this;

      this._super.apply(this, arguments);
      this._loadCurrentUser().catch(function () {
        return _this.get('session').invalidate();
      });
    },
    _loadCurrentAgency: function _loadCurrentAgency() {
      return this.get('currentAgency').load();
    },
    _loadCurrentUser: function _loadCurrentUser() {
      return this.get('currentUser').load();
    }
  });
});