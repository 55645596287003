define('illustrify/serializers/illustration', ['exports', 'illustrify/serializers/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      customerRec: { embedded: 'always' },
      quickViewData: { embedded: 'always' },
      policyData: { embedded: 'always' },
      policyAltData: { embedded: 'always' },
      policyCompData: { embedded: 'always' }
    }
  });
});