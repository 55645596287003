define('illustrify/validations/register', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    regCode: (0, _validators.validatePresence)({
      presence: true,
      message: 'A Registration Code is required.'
    }),
    agencyName: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ min: 2, max: 100 })],
    firstName: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ min: 2, max: 50 })],
    lastName: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ min: 2, max: 50 })],
    email: [(0, _validators.validatePresence)(true), (0, _validators.validateFormat)({ type: 'email' })],
    password: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ min: 6, max: 50 })],
    confirm: [(0, _validators.validatePresence)(true), (0, _validators.validateConfirmation)({ on: 'password' })]
  };
});