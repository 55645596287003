define('illustrify/components/form-agent', ['exports', 'ember-changeset', 'illustrify/validations/agent', 'ember-changeset-validations', 'illustrify/config/environment'], function (exports, _emberChangeset, _agent, _emberChangesetValidations, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      isEmpty = Ember.isEmpty,
      service = Ember.inject.service;
  exports.default = Ember.Component.extend({
    currentUser: service(),
    store: service(),
    setupData: service(),
    session: service(),
    flashMessages: service(),
    acceptedFiles: 'image/*',
    colSize: 'col-md-6',
    submitText: 'Update',
    titleText: 'Agent',
    displayUndoButton: true,
    isNewAgent: false,
    selectedRole: computed('changeset.role', 'setupData.roles', function () {
      if (isEmpty(this.get('changeset.role'))) {
        this.set('changeset.role', 'agent');
      }
      var selected = _.find(this.get('setupData.roles'), { 'id': this.get('changeset.role') });
      return selected;
    }),
    uploadCancelled: computed(function () {
      return function () {
        Dropzone.forElement('#my-drop-zone').removeAllFiles();
      };
    }),
    uploadFailed: computed(function () {
      var _this = this;

      return function (file, errorMessage) {
        _this.get('flashMessages').danger(errorMessage);
        Dropzone.forElement('#my-drop-zone').removeAllFiles();
      };
    }),
    newLogoUploaded: computed('currentUser.userId', function () {
      var _this2 = this;

      return function (fileUploaded, resp) {
        _this2.get('flashMessages').success('Saved...');
        _this2.set('agent.logoData', resp.logoData);
        Dropzone.forElement('#my-drop-zone').removeAllFiles();
      };
    }),
    uploadEndpoint: computed('agent.id', function () {
      return _environment.default.APP.API_HOST + '/' + _environment.default.APP.API_NAMESPACE + '/agents/' + this.get('agent.id') + '/logo/';
    }),
    headers: computed('session.data.authenticated.token', function () {
      return {
        'Accept': 'application/json',
        'Authorization': 'JWT ' + this.get('session.data.authenticated.token')
      };
    }),
    init: function init() {
      this._super.apply(this, arguments);
      var model = this.get('agent');
      if (!model.id) {
        model = this.get('store').modelFor('agent');
        this.isNewAgent = true;
      }
      this.changeset = new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(_agent.default), _agent.default);
      this.changeset.set('sendInvite', true);
      if (!this.get('changeset.role')) {
        this.set('changeset.role', 'agent');
      }
    },

    actions: {
      submit: function submit() {
        this.sendAction('submitAction', this.get('changeset'));
      },
      rollback: function rollback() {
        return this.get('changeset').rollback();
      },
      setRoleFieldValue: function setRoleFieldValue(value) {
        this.set('changeset.role', value.id);
      },
      setFieldValue: function setFieldValue(key, value) {
        this.get('changeset').set(key, value);
      }
    }
  });
});