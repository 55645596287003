define('illustrify/routes/secure/client/view', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      this.set('client_id', params.client_id);
      return this.store.findRecord('client', params.client_id);
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.set('client_id', this.get('client_id'));
    }
  });
});