define('illustrify/controllers/secure/client/view', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = Ember.Controller.extend({
    store: service(),
    actions: {
      newIllustration: function newIllustration() {
        var client_id = this.get('client_id');
        this.transitionToRoute('secure.illustration.index').then(function (newRoute) {
          newRoute.currentModel.set('customer', client_id);
          newRoute.controller.set('changeset.customer', parseInt(client_id));
        });
      }
    }
  });
});