define('illustrify/routes/secure/illustration/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = Ember.Route.extend({
    setupData: service(),
    lenderRate: service(),
    currentUser: service(),
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.set('clients', this.get('store').findAll('client'));
      this.get('lenderRate').firstYearRate(controller.get('model.lender')).then(function (firstYearRate) {
        controller.set('model.firstYearLiborRate', firstYearRate);
      });
    },
    model: function model() {
      var lender_id = _.find(this.get('setupData.lenders'), { default: true }).id;
      return this.get('store').createRecord('illustration', {
        agent: this.get('currentUser.userId'),
        customer: null,
        lender: lender_id,
        policy: null,
        programType: 'traditional',
        parsedPolicy: null,
        firstYearLiborRate: 0,
        yearsToPayInterest: 10,
        loanPayoffYear: 20,
        moneyTenThirtyFive: 0,
        lowPointYearOne: 0,
        lowPointYearTwo: 0,
        comparisonRetirementIncome: 0,
        stressTestRate: _.find(this.get('setupData.stressRates'), { default: true }).id
      });
    },

    actions: {
      createdIllustration: function createdIllustration(illustrationId) {
        this.transitionTo('secure.illustration.view', illustrationId);
      },
      willTransition: function willTransition(transition) {
        if (this.get('controller.changeset.isDirty')) {
          if (!confirm('You have unsaved changes.  Are you sure you want to leave this page?')) {
            transition.abort();
          } else {
            this.set('controller.client', null);
            this.get('store').deleteRecord(this.get('controller.model'));
          }
        } else {
          this.set('controller.client', null);
          if (this.get('controller.model.isNew')) {
            this.get('store').deleteRecord(this.get('controller.model'));
          }
        }
      }
    }
  });
});