define('illustrify/components/form-register', ['exports', 'ember-changeset', 'ember-changeset-validations', 'illustrify/validations/register', 'illustrify/config/environment'], function (exports, _emberChangeset, _emberChangesetValidations, _register, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = Ember.Component.extend({
    session: service(),
    flashMessages: service(),
    regCode: null,
    agencyName: null,
    firstName: null,
    lastName: null,
    email: null,
    password: null,
    confirm: null,
    // submitDisabled: computed.or('changeset.{isInvalid,isPristine}'),
    init: function init() {
      this._super.apply(this, arguments);
      this.changeset = new _emberChangeset.default(this, (0, _emberChangesetValidations.default)(_register.default), _register.default);
    },

    actions: {
      submit: function submit() {
        var _this = this;

        var self = this;
        //this.changeset.set('identification', this.changeset.get('email'));
        var apiUrl = _environment.default.APP.API_HOST + '/' + _environment.default.APP.API_NAMESPACE + '/register/';

        var data = {
          agencyName: this.changeset.get('agencyName'),
          firstName: this.changeset.get('firstName'),
          lastName: this.changeset.get('lastName'),
          email: this.changeset.get('email'),
          password: this.changeset.get('password'),
          regCode: this.changeset.get('regCode')
        };
        var requestObject = {
          url: apiUrl,
          method: 'POST',
          data: JSON.stringify(data),
          contentType: 'application/json',
          dataType: 'json',
          success: function success() {
            // let credentials = self.get('changeset').getProperties('identification', 'password');
            var credentials = self.get('changeset').getProperties('email', 'password');
            _this.get('session').authenticate('authenticator:jwt', credentials).catch(function () {
              _this.get('flashMessages').danger('Unable to login. Please try to login from the login page.');
            });
            _this.get('flashMessages').success('Welcome...');
          },
          error: function error(res) {
            if (_.has(res, 'responseJSON.error')) {
              _this.get('flashMessages').danger(res.responseJSON.error);
            } else {
              _this.get('flashMessages').danger('Unable to register. Please try again later.');
            }
          }
        };
        return Ember.$.post(requestObject);
      }
    }
  });
});