define('illustrify/mirage/factories/client', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var survivorPresent = _emberCliMirage.faker.random.boolean();

  exports.default = _emberCliMirage.Factory.extend({
    agent: 1,
    agentName: function agentName() {
      return _emberCliMirage.faker.name.findName();
    },
    agencyName: function agencyName() {
      return _emberCliMirage.faker.company.companyName();
    },
    firstName: function firstName() {
      return _emberCliMirage.faker.name.firstName();
    },
    lastName: function lastName() {
      return _emberCliMirage.faker.name.lastName();
    },
    addressState: function addressState() {
      return _emberCliMirage.faker.address.state();
    },
    birthDate: function birthDate() {
      return _emberCliMirage.faker.date.past();
    },
    svFirstName: function svFirstName() {
      if (survivorPresent) {
        return _emberCliMirage.faker.name.firstName();
      }
      return null;
    },
    svLastName: function svLastName() {
      if (survivorPresent) {
        return _emberCliMirage.faker.name.lastName();
      }
      return null;
    },
    svBirthDate: function svBirthDate() {
      if (survivorPresent) {
        return _emberCliMirage.faker.date.past();
      }
      return null;
    },
    created: function created() {
      return _emberCliMirage.faker.date.recent();
    },

    isActive: true
  });
});