define('illustrify/components/agent-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service;
  exports.default = Ember.Component.extend({
    currentUser: service(),
    currentAgency: service(),
    globalFilterTemplate: 'components/table-global-search',
    customMessages: computed('currentAgency.canInvite', function () {
      return {
        'addNewPath': 'secure.agents.new',
        'canAddNew': this.get('currentAgency.canInvite')
      };
    }),
    customClasses: {
      'table': 'table table-condensed table-striped table-hover'
    },
    columns: computed('currentUser.isSuperuser', function () {
      return [{
        "propertyName": "firstName",
        'routeName': 'secure.agents.view',
        "title": "First Name"
      }, {
        "propertyName": "lastName",
        'routeName': 'secure.agents.view',
        "title": "Last Name"
      }, {
        "propertyName": "email",
        "title": "Email"
      }, {
        "propertyName": "altAgencyName",
        "title": "Alt. Agency"
      }, {
        "propertyName": "agencyName",
        "title": "Agency"
      }, {
        "propertyName": "isSuperuser",
        'component': 'true-false-icon',
        "title": "Super",
        "isHidden": !this.get('currentUser.isSuperuser')
      }, {
        "propertyName": "isOwner",
        'component': 'true-false-icon',
        "title": "Owner"
      }, {
        "propertyName": "isActive",
        'component': 'true-false-icon',
        "title": "Active"
      }, {
        "propertyName": "lastLogin",
        'component': 'table-cell-date',
        "title": "Last Login"
      }, {
        "propertyName": "dateJoined",
        'component': 'table-cell-date',
        "title": "Joined"
      }];
    })
  });
});