define('illustrify/components/agency-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    globalFilterTemplate: 'components/table-global-search',
    customMessages: {
      'addNewPath': 'secure.agency',
      'canAddNew': true
    },
    customClasses: {
      'table': 'table table-condensed table-striped table-hover'
    },
    columns: [{
      "propertyName": "name",
      'routeName': 'secure.agency.view',
      "title": "Agency"
    }, {
      "propertyName": "address",
      "title": "Address"
    }, {
      "propertyName": "phone",
      "title": "Phone"
    }, {
      "propertyName": "canInvite",
      'component': 'true-false-icon',
      "title": "Invite?"
    }, {
      "propertyName": "isActive",
      'component': 'true-false-icon',
      "title": "Active"
    }, {
      "propertyName": "created",
      'component': 'table-cell-date',
      "title": "Joined"
    }]
  });
});