define('illustrify/components/wizard-step-indicator', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Component.extend({
    myWizardSteps: computed('wizardSteps', 'currentStep', function () {
      var _this = this;

      var procWizSteps = [];
      this.get('wizardSteps').map(function (wizardStep, index) {
        var styling = 'btn btn-circle';
        if (_this.get('currentStep') === index + 1) {
          styling = 'btn btn-circle-active';
        }
        procWizSteps.push({ step: wizardStep.step,
          id: wizardStep.id,
          name: wizardStep.name,
          styling: styling });
      });
      return procWizSteps;
    })
  });
});