define('illustrify/models/lender', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    addtlInterestCollateralRate: _emberData.default.attr('number'),
    advancedRateFee: _emberData.default.attr('boolean'),
    annualPolicyLoanOrWithdrawal: _emberData.default.attr('boolean'),
    baseRate: _emberData.default.attr('number'),
    capRate: _emberData.default.attr('number'),
    capitalizeAdvanceDrawFee: _emberData.default.attr('boolean'),
    cons: _emberData.default.attr('string'),
    csvCollateralValueRate: _emberData.default.attr('number'),
    deductRunningBalanceInterestOverage: _emberData.default.attr('boolean'),
    default: _emberData.default.attr('boolean'),
    initialLiborRate: _emberData.default.attr('number'),
    isActive: _emberData.default.attr('boolean'),
    liborRate: _emberData.default.attr('number'),
    locRate: _emberData.default.attr('number'),
    name: _emberData.default.attr('string'),
    payInterest: _emberData.default.attr('boolean'),
    pros: _emberData.default.attr('string'),
    scpDefault: _emberData.default.attr('boolean'),
    stressFormula: _emberData.default.attr('string')
  });
});