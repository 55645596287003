define("illustrify/components/illustration-view-config", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Component.extend({
    // IllustrationConfigValidator,
    // init() {
    //   this._super(...arguments);
    //   this.changeset = new Changeset(this.get('model.changeset'), lookupValidator(IllustrationConfigValidator), IllustrationConfigValidator);
    // },
    avgAnnualInterest: computed("changeset.{yearsToPayInterest,totalInterestInPaybackYears}", function () {
      return this.get("changeset.totalInterestInPaybackYears") / parseInt(this.get("changeset.yearsToPayInterest"));
    }),
    computedLoanInterest: computed("changeset.{additionalInterestPayment,fixedInterestPayment}", function () {
      //return parseFloat(this.get('changeset.fixedInterestPayment') ) + parseFloat(this.get('changeset.additionalInterestPayment') );
      return parseFloat(this.get("changeset.baseFixedInterestPayment")) + 2000.0 + parseFloat(this.get("changeset.additionalInterestPayment"));
    }),
    isSmallCasePlatform: computed("changeset.isSmallCasePlatform", function () {
      return this.get("changeset.isSmallCasePlatform");
    }),
    highlight10Pay: computed("changeset.yearsToPayInterest", function () {
      var yearsToPay = parseInt(this.get("changeset.yearsToPayInterest"));
      return yearsToPay === 10 ? "primary" : "default";
    }),
    highlight5Pay: computed("changeset.yearsToPayInterest", function () {
      var yearsToPay = parseInt(this.get("changeset.yearsToPayInterest"));
      return yearsToPay === 5 ? "primary" : "default";
    }),
    highlightDDA2Pay: computed("changeset.pdaYears", function () {
      var yearsToPay = parseInt(this.get("changeset.pdaYears"));
      return yearsToPay === 2 ? "primary" : "default";
    }),
    highlightDDA3Pay: computed("changeset.pdaYears", function () {
      var yearsToPay = parseInt(this.get("changeset.pdaYears"));
      return yearsToPay === 3 ? "primary" : "default";
    }),
    highlightDDA4Pay: computed("changeset.pdaYears", function () {
      var yearsToPay = parseInt(this.get("changeset.pdaYears"));
      return yearsToPay === 4 ? "primary" : "default";
    }),
    highlightDDA5Pay: computed("changeset.pdaYears", function () {
      var yearsToPay = parseInt(this.get("changeset.pdaYears"));
      return yearsToPay === 5 ? "primary" : "default";
    }),
    selectedPolicy: computed("changeset.policy", "setupData.policies", function () {
      //console.log( this.get('setupData.policies') );
      return _.find(this.get("setupData.policies"), {
        id: this.get("changeset.policy")
      }).name;
    }),
    selectedLender: computed("changeset.lender", "setupData.lenders", function () {
      return _.find(this.get("setupData.lenders"), {
        id: this.get("changeset.lender")
      });
    }),
    selectedCarrier: computed("changeset.carrier", "setupData.carriers", function () {
      return _.find(this.get("setupData.carriers"), {
        id: this.get("changeset.carrier")
      });
    }),
    selectedStressRate: computed("changeset.stressTestRate", "setupData.stressRates", function () {
      return _.find(this.get("setupData.stressRates"), {
        id: this.get("changeset.stressTestRate")
      });
    }),
    selectedStressRateSecondary: computed("changeset.stressTestRateSecondary", "setupData.stressRates", function () {
      return _.find(this.get("setupData.stressRates"), {
        id: this.get("changeset.stressTestRateSecondary")
      });
    }),
    actions: {
      selectRecord: function selectRecord(key, value) {
        this.send("setFieldValue", key, value.id);
      },
      setFieldValue: function setFieldValue(key, value) {
        this.sendAction("updateAction", key, value);
      },
      submit: function submit() {
        this.sendAction("submitAction");
      },
      rollback: function rollback() {
        //console.log('validation failed');
        this.sendAction("rollbackAction");
      }
    }
  });
});