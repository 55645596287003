define('illustrify/components/form-forgotpw', ['exports', 'ember-changeset', 'illustrify/validations/forgotpw', 'ember-changeset-validations', 'illustrify/config/environment'], function (exports, _emberChangeset, _forgotpw, _emberChangesetValidations, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service;
  exports.default = Ember.Component.extend({
    sentryLogger: service(),
    flashMessages: service(),
    email: null,
    errorMessage: null,
    showSuccess: false,
    submitDisabled: computed.or('changeset.{isInvalid,isPristine}'),
    init: function init() {
      this._super.apply(this, arguments);
      this.changeset = new _emberChangeset.default(this, (0, _emberChangesetValidations.default)(_forgotpw.default), _forgotpw.default);
    },

    actions: {
      submit: function submit() {
        var _this = this;

        var apiUrl = _environment.default.APP.API_HOST + '/' + _environment.default.APP.API_NAMESPACE + '/forgot/';
        var data = {
          email: this.changeset.get('email')
        };
        var requestObject = {
          url: apiUrl,
          method: 'POST',
          data: JSON.stringify(data),
          contentType: 'application/json',
          dataType: 'json',
          success: function success() {
            _this.toggleProperty('showSuccess');
            _this.get('flashMessages').success('An email will be sent to the address provided, if it exists, with reset instructions.');
          },
          error: function error(res) {
            _this.set('errorMessage', 'Unable to send a reset email. Please try again later.');
            _this.get('sentryLogger').captureException(new Error(res.responseJSON.detail || 'doh, something broke'));
          }
        };
        return Ember.$.post(requestObject);
      }
    }
  });
});