define("illustrify/components/illustration-view-alternate-data", ["exports", "illustrify/config/environment"], function (exports, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service;
  exports.default = Ember.Component.extend({
    flashMessages: service(),
    session: service(),
    isEditing: false,
    snapshot: null,
    acceptedFiles: ".csv, .xls, .xlsx",
    isSmallCasePlatform: computed("changeset.isSmallCasePlatform", function () {
      return this.get("changeset.isSmallCasePlatform");
    }),
    availPolicies: computed("setupData.policies", "isSmallCasePlatform", function () {
      if (this.get("isSmallCasePlatform")) {
        return _.filter(this.get("setupData.policies", { isScp: true }));
      } else {
        return _.filter(this.get("setupData.policies", { isTraditional: true }));
      }
    }),
    selectedPolicy: computed("changeset.policy", "availPolicies", function () {
      return _.find(this.get("availPolicies"), {
        id: this.get("changeset.policy")
      });
    }),
    fileSending: computed("session.data.authenticated.token", function () {
      var self = this;
      return function (file, xhr) {
        xhr.setRequestHeader("Accept", "*/*");
        xhr.setRequestHeader("Authorization", "JWT " + self.get("session.data.authenticated.token"));
      };
    }),
    fileUploadSuccess: computed("processParseSuccess", function () {
      var self = this;
      return function (file, resp) {
        self.processParseSuccess(resp);
        Dropzone.forElement("#scp-drop-zone").removeAllFiles();
      };
    }),
    fileUploadError: computed("processParseError", function () {
      var self = this;
      return function (file, errorMsg, resp) {
        self.processParseError(resp);
        Dropzone.forElement("#scp-drop-zone").removeAllFiles();
      };
    }),
    uploadEndpoint: computed("changeset.policy", function () {
      var self = this;
      return function () {
        return _environment.default.APP.API_HOST + "/" + _environment.default.APP.API_NAMESPACE + "/parser_v2/" + self.get("changeset.policy") + "/altcsv/";
      };
    }),
    procPastedData: function procPastedData(data, e) {
      var _this = this;

      e.target.value = "";
      if (!this.get("parsingData")) {
        this.set("parsingData", true);
        var requestObject = {
          url: this.get("uploadEndpoint")(),
          type: "POST",
          data: { policyData: data },
          dataType: "json",
          success: function success(resp) {
            Ember.$("#pasteTextArea-field").val("");
            _this.processParseSuccess(resp);
          },
          error: function error(resp) {
            if (resp.hasOwnProperty("status") && resp.status === 401) {
              _this.get("session").invalidate();
            }
            Ember.$("#pasteTextArea-field").val("");
            _this.processParseError(resp);
          },
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader("Authorization", "JWT " + _this.get("session.data.authenticated.token"));
          }
        };
        Ember.$.ajax(requestObject);
      }
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);
      this.$("#pasteTextArea-field").one("paste", _.debounce(function (e) {
        var content = e.target.value;
        this.procPastedData(content, e);
      }.bind(this), 500));
    },
    processParseSuccess: function processParseSuccess(resp) {
      this.set("parsingData", false);
      this.send("setFieldValue", "parsedPolicyAlt", resp.id);
      this.set("parsedAltData", resp.data["rows"]);
      this.get("flashMessages").success("Alt. Policy Data Refreshed...");
      //Ember.$('#comp-drop-zone,#pasteTextArea-field').val('');
    },
    processParseError: function processParseError(resp) {
      if (resp.status === 401) {
        this.get("flashMessages").danger("Session Expired, please login...");
        this.get("session").invalidate();
      }
      this.set("parsingData", false);
      this.get("flashMessages").danger("Failed to parse. Please try again...");
      var respJSON = JSON.parse(resp.response);
      if (respJSON.hasOwnProperty("error")) {
        this.get("flashMessages").danger(respJSON.error);
      }
    },

    actions: {
      resetParsedData: function resetParsedData() {
        this.get("changeset").restore(this.get("snapshot"));
        //Ember.$('#comp-drop-zone,#pasteTextArea-field').val('');
        this.toggleProperty("isEditing");
      },
      selectPolicy: function selectPolicy(value) {
        this.set("parsedAltData", null);
        this.send("setFieldValue", "parsedPolicyAlt", null);
        if (value.defaultLender !== null && value.defaultLender !== this.get("changeset.lender")) {
          var selectedLender = _.find(this.get("setupData.lenders"), {
            id: value.defaultLender
          });
          this.send("setFieldValue", "lender", selectedLender.id);
          this.get("flashMessages").info("Lender Changed...");
        }
        this.send("setFieldValue", "policy", value.id);
      },
      setFieldValue: function setFieldValue(key, value) {
        this.sendAction("updateAction", key, value);
      },
      toggleEdit: function toggleEdit() {
        this.set("snapshot", this.get("changeset").snapshot());
        //console.log( this.get('snapshot') );
        this.set("parsedAltData", null);
        this.toggleProperty("isEditing");
      },
      submit: function submit() {
        this.sendAction("submitAction");
        this.send("toggleEdit");
      }
    }
  });
});