define('illustrify/components/client-card', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service;
  exports.default = Ember.Component.extend({
    currentUser: service(),
    hasClient: computed.notEmpty('client'),
    isTraditional: computed('program', function () {
      return this.get('program') === "traditional";
    })
  });
});