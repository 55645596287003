define('illustrify/services/current-user', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service;
  exports.default = Ember.Service.extend({
    session: service(),
    store: service(),
    userId: null,
    firstName: null,
    lastName: null,
    role: null,
    isSuperuser: false,
    fullName: computed('firstName', 'lastName', function () {
      return this.get('firstName') + ' ' + this.get('lastName');
    }),
    isOwner: computed('role', function () {
      return this.get('role') === 'owner';
    }),
    isAdmin: computed('isOwner', 'isSuperuser', function () {
      return this.get('isOwner') || this.get('isSuperuser');
    }),
    isManager: computed('role', function () {
      return this.get('role') !== 'agent';
    }),
    load: function load() {
      var _this = this;

      if (this.get('session.isAuthenticated')) {
        return this.get('store').queryRecord('user', {}).then(function (user) {
          // Raven.setUserContext({
          //   email: user.email,
          //   name: user.fullName,
          //   id: user.id
          // });
          _this.set('userId', user.id);
          user.eachAttribute(function (name) {
            _this.set(name, user.get(name));
          });
        });
      }
    }
  });
});