define("illustrify/models/illustration", ["exports", "ember-data", "ember-data/relationships"], function (exports, _emberData, _relationships) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    agent: _emberData.default.attr("number"),
    advancedDrawFee: _emberData.default.attr("number"),
    agencyName: _emberData.default.attr("string"),
    agentName: _emberData.default.attr("string"),
    arrangementFeeRate: _emberData.default.attr("number"),
    arrangementFeeRollup: _emberData.default.attr("boolean", { defaultValue: true }),
    calculatedInterestRate: _emberData.default.attr("number", { defaultValue: 0 }),
    capAdvancedDrawFee: _emberData.default.attr("boolean", { defaultValue: true }),
    capArrangementFee: _emberData.default.attr("boolean", { defaultValue: true }),
    carrier: _emberData.default.attr("number"),
    clientPaidPremium: _emberData.default.attr("number", { defaltValue: 0 }),
    comparisonRetirementIncome: _emberData.default.attr("number", { defaultValue: 0 }),
    created: _emberData.default.attr("date"),
    csvCollateralValueRate: _emberData.default.attr("number"),
    csvCollateralStressRate: _emberData.default.attr("number"),
    currentInterestRate: _emberData.default.attr("number"),
    customer: _emberData.default.attr("number"),
    customerAge: _emberData.default.attr("number"),
    customerName: _emberData.default.attr("string"),
    customerRec: (0, _relationships.belongsTo)("client", { inverse: null }),
    deathBenefit: _emberData.default.attr("number"),
    description: _emberData.default.attr("string"),
    firstYearLiborRate: _emberData.default.attr("number"),
    fixedInterestPayment: _emberData.default.attr("number"),
    fmPct: _emberData.default.attr("number"),
    gpulPremium: _emberData.default.attr("number"),
    gpulFaceAmount: _emberData.default.attr("number"),
    indexedUl: _emberData.default.attr("boolean"),
    isProgramTraditional: _emberData.default.attr("boolean"),
    isSmallCasePlatform: _emberData.default.attr("boolean"),
    lastModified: _emberData.default.attr("date"),
    lender: _emberData.default.attr("number"),
    lenderAddtlInterestCollateralRate: _emberData.default.attr("number"),
    lenderCollateralValueRate: _emberData.default.attr("number"),
    lenderLocFee: _emberData.default.attr("boolean", { defaultValue: true }),
    lenderLocRate: _emberData.default.attr("number"),
    lenderName: _emberData.default.attr("string"),
    loanPayoffAmount: _emberData.default.attr("number", { defaultValue: 0 }),
    loanPayoffYear: _emberData.default.attr("number", { defaultValue: 0 }),
    loanRescueAmount: _emberData.default.attr("number", { defaultValue: 0 }),
    lowPointYearOne: _emberData.default.attr("number", { defaultValue: 0 }),
    lowPointYearTwo: _emberData.default.attr("number", { defaultValue: 0 }),
    moneyTenThirtyFive: _emberData.default.attr("number", { defaultValue: 0 }),
    payFixedInterest: _emberData.default.attr("boolean"),
    payInterestAnnually: _emberData.default.attr("boolean", { defaultValue: true }),
    parsedPolicy: _emberData.default.attr("number"),
    parsedPolicyAlt: _emberData.default.attr("number", { defaultValue: null }),
    parsedPolicyComp: _emberData.default.attr("number", { defaultValue: null }),
    pdaAccount: _emberData.default.attr("boolean", { defaultValue: false }),
    pdaYears: _emberData.default.attr("number", { defaultValue: 1 }),
    policy: _emberData.default.attr("number"),
    policyData: _emberData.default.hasMany("policy-data"),
    policyAltData: _emberData.default.hasMany("policy-alt-data"),
    policyCompData: _emberData.default.hasMany("policy-comp-data"),
    policyName: _emberData.default.attr("string"),
    proposalData: _emberData.default.attr(),
    programType: _emberData.default.attr("string"),
    programTypeDisplay: _emberData.default.attr("string"),
    quickViewData: _emberData.default.belongsTo("quick-view-data"),
    rcaInterestRate: _emberData.default.attr("number", { defaultValue: 0.07 }),
    retirementIncome: _emberData.default.attr("number"),
    retirementYear: _emberData.default.attr("number"),
    selectIraAdvantage: _emberData.default.attr("boolean"),
    status: _emberData.default.attr("string"),
    stressTestRate: _emberData.default.attr("number"),
    stressTestRateSecondary: _emberData.default.attr("number"),
    targetPremium: _emberData.default.attr("number"),
    totalInterestInPaybackYears: _emberData.default.attr("number"),
    yearsToPayInterest: _emberData.default.attr("number"),
    additionalInterestPayment: _emberData.default.attr("number", { defaultValue: 0.0 }),
    baseFixedInterestPayment: _emberData.default.attr("number"),
    rawPolicyData: _emberData.default.attr(),

    avgAnnualInterest: computed("totalInterestInPaybackYears", "yearsToPayInterest", function () {
      return this.get("totalInterestInPaybackYears") / this.get("yearsToPayInterest");
    }),

    createdFormat: computed("created", function () {
      return moment.utc(this.get("created")).format("YYYY-MM-DD HH:mm:ss");
    }),
    lastModifiedFormat: computed("lastModified", function () {
      return moment.utc(this.get("lastModified")).format("YYYY-MM-DD HH:mm:ss");
    })
  });
});