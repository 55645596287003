define('illustrify/models/client', ['exports', 'ember-data', 'numeral'], function (exports, _emberData, _numeral) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    agent: _emberData.default.attr('number'),
    agentName: _emberData.default.attr('string'),
    agencyName: _emberData.default.attr('string'),
    firstName: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    // address: DS.attr('string'),
    addressState: _emberData.default.attr('string'),
    birthDate: _emberData.default.attr('string'),
    svFirstName: _emberData.default.attr('string', { defaultValue: '' }),
    svLastName: _emberData.default.attr('string', { defaultValue: '' }),
    svBirthDate: _emberData.default.attr('string', { defaultValue: '' }),
    created: _emberData.default.attr('date'),
    isActive: _emberData.default.attr('boolean'),
    illustrations: _emberData.default.attr(),

    race: _emberData.default.attr('string'),
    gender: _emberData.default.attr('string'),
    rateclass: _emberData.default.attr('string'),

    fullName: computed('firstName', 'lastName', function () {
      return (this.get('lastName') + ', ' + this.get('firstName')).replace(/^\s+|\s+$/g, '');
    }),
    birthDateDate: computed('birthDate', function () {
      return this.get('birthDate') ? moment(this.get('birthDate'), 'MM-DD-YYYY') : null;
    }),
    currentAge: computed('birthDate', function () {
      return moment(this.get('birthDate'), 'MM-DD-YYYY').isValid() ? (0, _numeral.default)(moment().diff(moment(this.get('birthDate'), 'MM-DD-YYYY'), 'years', true)).format('0.00') : '';
    }),
    hasSurvivorship: computed.notEmpty('svFirstName', 'svLastName', 'svBirthDate'),
    svFullName: computed('svFirstName', 'svLastName', function () {
      return this.get('svFirstName') && this.get('svLastName') ? (this.get('svLastName') + ', ' + this.get('svFirstName')).replace(/^\s+|\s+$/g, '') : '';
    }),
    svBirthDateDate: computed('svBirthDate', function () {
      return this.get('svBirthDate') ? moment(this.get('svBirthDate'), 'MM-DD-YYYY') : null;
    }),
    svCurrentAge: computed('svBirthDate', function () {
      return moment(this.get('svBirthDate'), 'MM-DD-YYYY').isValid() ? (0, _numeral.default)(moment().diff(moment(this.get('svBirthDate'), 'MM-DD-YYYY'), 'years', true)).format('0.00') : '';
    })

  });
});