define('illustrify/adapters/application', ['exports', 'ember-data', 'illustrify/config/environment', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _emberData, _environment, _dataAdapterMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTAdapter.extend(_dataAdapterMixin.default, {
    host: _environment.default.APP.API_HOST,
    namespace: _environment.default.APP.API_NAMESPACE,
    authorizer: 'authorizer:token',
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      var url = this._super(modelName, id, snapshot, requestType, query);
      if (url.charAt(url.length - 1) !== '/') {
        url += '/';
      }
      return url;
    }
  });
});