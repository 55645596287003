define('illustrify/routes/secure/agents/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = Ember.Route.extend({
    flashMessages: service(),
    savingAgent: false,
    actions: {
      submit: function submit(changeset) {
        var _this = this;

        if (changeset.get('isPristine')) {
          this.get('flashMessages').info('Nothing to Create');
        } else {
          this.set('savingAgent', true);
          var newAgent = this.get('store').createRecord('agent', {
            firstName: changeset.get('firstName'),
            lastName: changeset.get('lastName'),
            email: changeset.get('email'),
            altAgencyName: changeset.get('altAgencyName'),
            role: changeset.get('role'),
            isSuperuser: changeset.get('isSuperuser'),
            sendInvite: changeset.get('sendInvite')
          });
          newAgent.save().then(function () {
            _this.get('flashMessages').success('Invited...');
            _this.transitionTo('secure.agents.view', newAgent);
          }).catch(function (response) {
            if (response.hasOwnProperty('error')) {
              _this.get('flashMessages').danger(response.error);
              if (response.hasOwnProperty('errors')) {
                response.errors.map(function (error) {
                  changeset.pushErrors(error[0], error[1]);
                });
              }
            } else {
              _this.get('flashMessages').danger('Unable to invite agent. Try again later');
            }
          }).finally(function () {
            _this.set('savingAgent', false);
          });
        }
      }
    }
  });
});