define('illustrify/components/client-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service;
  exports.default = Ember.Component.extend({
    currentAgency: service(),
    sortProperties: ['created:desc'],
    sortedModel: computed.sort('model', 'sortProperties'),
    globalFilterTemplate: 'components/table-global-search',
    customMessages: computed('currentAgency.canAddClient', function () {
      return {
        'addNewPath': 'secure.client.index',
        'canAddNew': this.get('currentAgency.canAddClient')
      };
    }),
    customClasses: {
      'table': 'table table-condensed table-striped table-hover'
    },
    columns: [{
      "propertyName": "firstName",
      'routeName': 'secure.client.view',
      "title": "First Name"
    }, {
      "propertyName": "lastName",
      'routeName': 'secure.client.view',
      "title": "Last Name"
    }, {
      "propertyName": "birthDate",
      "title": "DOB"
    }, {
      "propertyName": "currentAge",
      'className': 'text-right',
      "title": "Age"
    }, {
      "propertyName": "addressState",
      "filterWithSelect": true,
      "title": "State"
    }, {
      "propertyName": "svFirstName",
      "title": "First Name"
    }, {
      "propertyName": "svLastName",
      "title": "Last Name"
    }, {
      "propertyName": "svBirthDate",
      "title": "DOB"
    }, {
      "propertyName": "svCurrentAge",
      'className': 'text-right',
      "title": "Age"
    }, {
      "propertyName": "created",
      'className': 'text-right',
      'component': 'table-cell-date',
      "title": "Created"
    }],
    groupedHeaders: [[{
      "title": "Client",
      "colspan": 5
    }, {
      "title": "Survivorship",
      "colspan": 4
    }]]
  });
});