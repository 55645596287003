define('illustrify/models/user', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    email: _emberData.default.attr('string'),
    agency: _emberData.default.attr('number'),
    agencyName: _emberData.default.attr('string'),
    firstName: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    isStaff: _emberData.default.attr('boolean'),
    isSuperuser: _emberData.default.attr('boolean'),
    isActive: _emberData.default.attr('boolean'),
    dateJoined: _emberData.default.attr('date'),
    lastLogin: _emberData.default.attr('date'),
    logoData: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    role: _emberData.default.attr('string'),
    fullName: computed('firstName', 'lastName', function () {
      return this.get('firstName') + ' ' + this.get('lastName');
    }),
    isManager: computed('role', function () {
      return this.get('role') !== 'agent';
    }),
    isOwner: computed('role', function () {
      return this.get('role') === 'owner';
    })
  });
});