define('illustrify/helpers/range-cell-colorizer', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.rangeCellColorizer = rangeCellColorizer;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function rangeCellColorizer(_ref) {
    var _ref2 = _slicedToArray(_ref, 4),
        val = _ref2[0],
        minVal = _ref2[1],
        maxVal = _ref2[2],
        _ref2$ = _ref2[3],
        extra_style = _ref2$ === undefined ? '' : _ref2$;

    var valPct = (val - minVal) / (maxVal - minVal) * 100;
    var hue = Math.floor((100 - valPct) * 120 / 100);
    if (hue === -Infinity) {
      return Ember.String.htmlSafe('background-color: hsla(0, 100%, 50%, 0.5);' + extra_style);
    }
    return Ember.String.htmlSafe('background-color: hsla(' + hue + ', 100%, 50%, 0.5);' + extra_style);
  }

  exports.default = Ember.Helper.helper(rangeCellColorizer);
});