define('illustrify/validations/agency', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ min: 2, max: 100 })],
    phoneNumber: [(0, _validators.validateLength)({ min: 0, max: 120 })]
  };
});