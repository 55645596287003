define('illustrify/services/lender-rate', ['exports', 'illustrify/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Promise = Ember.RSVP.Promise;
  exports.default = Ember.Service.extend({
    session: service(),
    store: service(),
    lastLenderId: null,
    lastFirstYearRate: null,
    firstYearRate: function firstYearRate(lender_id) {
      var _this = this;

      return new Promise(function (resolve, reject) {
        if (_this.get('session.isAuthenticated')) {
          if (lender_id === _this.lastLenderId) {
            resolve(_this.lastFirstYearRate);
          }
          var requestObject = {
            url: _environment.default.APP.API_HOST + '/' + _environment.default.APP.API_NAMESPACE + '/lender_rate/' + lender_id + '/',
            type: 'GET',
            contentType: 'JSON',
            dataType: 'json',
            success: function success(resp) {
              _this.lastLenderId = lender_id;
              _this.lastFirstYearRate = resp.firstYearLiborRate;
              resolve(resp.firstYearLiborRate);
            },
            error: function error(resp) {
              if (resp.status === 401) {
                _this.get('session').invalidate();
              }
              reject(resp.error);
            },
            beforeSend: function beforeSend(xhr) {
              xhr.setRequestHeader('Authorization', 'JWT ' + _this.get('session.data.authenticated.token'));
            }
          };
          Ember.$.ajax(requestObject);
        }
      });
    }
  });
});