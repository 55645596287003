define('illustrify/components/navbar-main', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = Ember.Component.extend({
    session: service(),
    currentUser: service(),
    actions: {
      logoutUser: function logoutUser() {
        this.get('session').invalidate();
      }
    }
  });
});