define('illustrify/validations/illustration_policy_data', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    policy: (0, _validators.validatePresence)(true),
    parsedDataId: (0, _validators.validatePresence)(true),
    moneyTenThirtyFive: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ gte: 0, integer: true, message: 'Must be whole number, greater than or equal to 0.' })],
    lowPointYearOne: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ gte: 0, integer: true, message: 'Must be whole number, greater than or equal to 0.' })],
    lowPointYearTwo: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ gte: 0, integer: true, message: 'Must be whole number, greater than or equal to 0.' })]
  };
});